.more-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  box-shadow: -1px 2px 5px 0px #47474729;
  background: white;
}
.more-icon:hover {
  background-color: white;
}
.lg-modal .MuiBox-root {
  min-width: 85%;
  max-height: 90%;
  overflow: scroll;
  border-radius: 0px;
  border: none;
}
.pointer {
  cursor: pointer;
}
.upload_image_container.error-file {
  border-color: red;
  color: red;
}
.date-input-custom {
  color: #637381 !important;
}
/* Details Pages */

.container {
  max-width: 1170px;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.es-content-box {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.95);
  position: relative;
  z-index: 1;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 10%);
}
.d-lg-flex {
  display: flex !important;
  flex-wrap: wrap !important;
}
.esu-left {
  width: 55.7%;
}
.esu-left .featured-image {
  width: 100%;
  height: 640px;
  position: relative;
  /* padding-bottom: 15%; */
  border-radius: 6px 6px 0 0;
}
.esu-left .featured-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}
.esu-right {
  padding: 30px 30px 0;
  width: 44.3%;
}
.es-content {
  font-size: 16px;
  line-height: 19px;
}
.esc-text-box {
  color: #dd090f;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
}
.esc-img {
  position: absolute;
  top: 0;
  left: 0;
}
.es-content h4 {
  font-size: 21px;
  line-height: 33px;
  letter-spacing: 0.4px;
  margin-bottom: 12px;
}
.capitalize {
  text-transform: capitalize;
}
h4 {
  font-size: 24px !important;
  font-weight: bold;
}
.es-content h6 {
  font-size: 16px;
  line-height: 19px;
  color: #dd090f;
  font-weight: 400;
  margin-bottom: 10px;
}
p {
  font-size: 15px;
  font-family: 'Now Regular';
  color: #444444;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px 0;
  white-space: pre-line;
}
.speakers-list {
  width: calc(100% + 20px);
  margin-left: -10px;
  list-style: none;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.speakers-list button {
  width: 100%;
  padding: 10px;
  background: transparent;
  border: none;
  text-align: left;
}
.speakers-list {
  transition: 0.4s linear;
}
.speakers-list {
  width: calc(100% + 20px);
  margin-left: -10px;
  list-style: none;
}
.speakers-list .social-link {
  margin-bottom: 15px;
}
.social-link {
  vertical-align: top;
  width: calc(100% + 5px);
  display: flex;
  gap: 20px;
  list-style: none;
}
.speakers-list .social-link li {
  width: fit-content;
  padding: 0px;
}
.pointer {
  cursor: pointer;
}
.speakers-box {
  padding: 15px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  position: relative;
}
.speakers-img-box {
  height: 100%;
  border-radius: 5px;
}
.speakers-img-box {
  width: 25%;
  /* height: 40px; */
  /* border-radius: 50%; */
  overflow: hidden;
  margin-right: 10px;
}
.speakers-text-box {
  /* width: calc(100% - 50px); */
  width: 71%;
}
.speakers-text-box strong {
  font-size: 14px;
  line-height: 16px;
}
.speakers-text-box p {
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
}
.es-btn-box {
  margin-top: 10px;
  display: flex;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn {
  border-radius: 3px;
  font-weight: 400;
  padding: 10px 15px;
  min-width: 116px;
  font-size: 16px;
  line-height: 18px;
  box-shadow: none;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.es-lower {
  padding: 40px 30px 40px 40px;
}
.social-link {
  vertical-align: top;
  width: calc(100% + 5px);
  display: flex;
  gap: 20px;
  list-style: none;
}
.btn-orange,
.btn-orange:focus {
  color: #fff;
  background-color: #dd090f;
  border: solid 1px #dd090f;
}
@media (max-width: 991.98px) {
  .esu-left {
    width: 100%;
  }
  .esu-right {
    padding: 25px 25px 0;
    width: 100%;
  }
  .social-link {
    margin-top: 0;
  }
  .es-lower {
    padding: 35px 30px;
  }
  .esl-left {
    width: 100%;
    padding-right: 0;
  }
  .esl-right {
    width: 100%;
    padding-left: 0;
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/* BLOG DETAILS */
.blog-details {
  padding-bottom: 60px;
  padding-top: 70px;
}
.blog__details__item {
  margin-bottom: 32px;
}
.blog__details__item__text {
  padding-top: 30px;
}
.blog__details__item__text h3 {
  color: #111111;
  font-weight: 700;
  line-height: 31px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.blog__details__item__widget ul li {
  font-size: 13px;
  color: #888888;
  display: inline-block;
  list-style: none;
  margin-right: 20px;
  position: relative;
}
.blog__details__item__widget ul li:last-child {
  margin-right: 0;
}
.blog__details__item__text h3 {
  color: #111111;
  font-weight: 700;
  line-height: 31px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.blog__details__item {
  margin-bottom: 32px;
}
.blog__details__desc {
  margin-bottom: 54px;
}
.blog__details__desc p:last-child {
  margin-bottom: 0;
}
.blog__details__desc p {
  margin-bottom: 20px;
}
.blog__details__item__pic {
  height: 385px;
  position: relative;
}
@media (max-width: 991.98px) {
  .blog__details__item__text {
    padding: 10px 0 0px 10px;
  }
  .blog__details__desc {
    padding: 0 10px;
  }
}
